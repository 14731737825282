import {
  columnCopy,
  columnFormatApplicant,
  columnFormatDate,
  columnFormatMeetup,
  DeprecatedDataEntityTable,
  DeprecatedEntityTable,
  DeprecatedEntityTableProps,
  deprecatedRenderColumn,
} from "../../components/EntityTable/DeprecatedEntityTable";
import * as yup from "yup";
import {
  deprecatedCreateEntityFieldMeta,
  DeprecatedEntityFieldSelectOption,
  DeprecatedEntityFieldType,
} from "../../components/EntityTable/DeprecatedEntityForm";
import { useFetch } from "../../hooks/useFetch";
import { useMemo } from "react";
import {
  CheckCircleOutline,
  HourglassEmpty,
  CircleNotificationsOutlined,
  HowToVote,
  Extension,
  Flag,
  RateReview,
  CancelOutlined,
  DisabledByDefault,
  CheckBox,
} from "@mui/icons-material";
import { createApplicationValidator } from "../../validators/applicationValidators";
import { canTransitionStatus } from "../../utils/StatusFlow";
import {
  ApplicationModel,
  ApplicationStatus,
  ApplicationWith,
  GameModel,
  UserEmailTemplate,
} from "@pastukh-dm/wboard-games";

export const ApplicationsPage = () => {
  return (
    <>
      <ApplicationsTable />
    </>
  );
};

export const ApplicationsTable = () => {
  const props = useApplicationTable();
  return <DeprecatedEntityTable {...props} />;
};

export const ApplicationsDataTable = ({
  data,
  singleItem,
}: {
  data: ApplicationModel[];
  singleItem?: boolean;
}) => {
  const props = useApplicationTable();
  return (
    <DeprecatedDataEntityTable {...props} data={data} singleItem={singleItem} />
  );
};

const useApplicationTable =
  (): DeprecatedEntityTableProps<ApplicationModel> => {
    const { data: meetups } = useFetch<{ id: string; name: string }[]>({
      url: "/meetups/",
    });
    const { data: users } = useFetch<
      { id: string; name: string; email: string }[]
    >({ url: "/users" });

    const { data: games } = useFetch<GameModel[]>({ url: "/games" });

    const validationSchema: any = useMemo(() => {
      const userOptions =
        users && users.length
          ? users.map<DeprecatedEntityFieldSelectOption>((item) => ({
              value: item.id,
              label: `${item.name} - ${item.email}`,
            }))
          : [];
      const meetupOptions =
        meetups && meetups.length
          ? meetups.map<DeprecatedEntityFieldSelectOption>((item) => ({
              value: item.id,
              label: item.name,
            }))
          : [];
      return createApplicationValidator({ meetupOptions, userOptions });
    }, [users, meetups]);

    const gameOptions =
      games && games.length
        ? games.map<DeprecatedEntityFieldSelectOption>((item) => ({
            value: item.id,
            label: `${item.name} (${item.geekId})`,
          }))
        : [];

    const sharedProps: Omit<
      DeprecatedEntityTableProps<ApplicationModel>,
      "validationSchema"
    > = {
      entityName: "Application",
      apiUrls: {
        create: "/applications",
        read: "/applications",
        update: "/applications/:id",
        delete: "/applications/:id",
      },
      columns: [
        deprecatedRenderColumn("id", columnCopy({ maxLength: 12 })),
        deprecatedRenderColumn("meetup", columnFormatMeetup),
        deprecatedRenderColumn("user", columnFormatApplicant),
        "status",
        "games",
        "mainGameId",
        deprecatedRenderColumn("createdAt", columnFormatDate),
        deprecatedRenderColumn("updatedAt", columnFormatDate),
      ],
      datatableOptions: {
        sortOrder: { name: "updatedAt", direction: "desc" },
      },
      itemUrl: "/applications/:id",
      // itemActions: [
      //   {
      //     actionName: "Remind accepted",
      //     url: "/applications/:id/remind",
      //     icon: <CircleNotificationsOutlined />,
      //     hide: (item: ApplicationModel) =>
      //       !canTransitionStatus(item.status, ApplicationStatus.ACCEPTED),
      //     disable: (item: ApplicationWith<"userEmails">) => {
      //       const found = item.userEmails?.find(
      //         (email) =>
      //           email.template === UserEmailTemplate.APPLICATION_ACCEPTED_REMINDER
      //       );
      //       return !!found;
      //     },
      //   },
      //   {
      //     url: `/applications/:id/mainGame`,
      //     icon: <Extension />,
      //     actionName: "Add game",

      //     validationSchema: yup.object({
      //       // geekId: yup.number().required(),
      //       // isMainGame: yup.boolean(),
      //       mainGame: yup
      //         .string()
      //         .oneOf(gameOptions.map((i) => i.value))
      //         .required()
      //         .meta(
      //           createEntityFieldMeta(EntityFieldType.AUTOCOMPLETE, {
      //             options: gameOptions,
      //           })
      //         ),
      //     }) as any,
      //     hide: (item: ApplicationModel) =>
      //       item.status !== ApplicationStatus.VOTED || !!item.assignedGameId,

      //     defaultValues: (e: any) => {
      //       return { mainGame: e.mainGame };
      //     },
      //   },
      //   {
      //     actionName: "Accept",
      //     url: "/applications/:id/accept",
      //     icon: <CheckCircleOutline />,
      //     hide: (item: ApplicationModel) =>
      //       !canTransitionStatus(item.status, ApplicationStatus.ACCEPTED),
      //   },
      //   {
      //     actionName: "Assign",
      //     url: "/applications/:id/assign",
      //     icon: <Flag />,
      //     hide: (item: ApplicationModel) =>
      //       !canTransitionStatus(item.status, ApplicationStatus.GAME_ASSIGNED),
      //   },
      //   {
      //     actionName: "Set arrived",
      //     url: "/applications/:id/arrived",
      //     icon: <CheckBox color="success" />,
      //     hide: (item: ApplicationModel) =>
      //       !canTransitionStatus(item.status, ApplicationStatus.ARRIVED),
      //   },
      //   {
      //     actionName: "Set no arrival",
      //     url: "/applications/:id/noArrival",
      //     icon: <DisabledByDefault color="error" />,
      //     hide: (item: ApplicationModel) =>
      //       !canTransitionStatus(item.status, ApplicationStatus.NO_ARRIVAL),
      //   },
      //   {
      //     actionName: "Request feedback",
      //     url: "/applications/:id/askFeedback",
      //     icon: <RateReview />,
      //     hide: (item: ApplicationModel) =>
      //       !canTransitionStatus(
      //         item.status,
      //         ApplicationStatus.FEEDBACK_REQUESTED
      //       ),
      //   },
      //   {
      //     actionName: "Remind feedback",
      //     url: "/applications/:id/askFeedback/remind",
      //     icon: <CircleNotificationsOutlined />,
      //     hide: (item: ApplicationModel) =>
      //       !canTransitionStatus(
      //         item.status,
      //         ApplicationStatus.FEEDBACK_RECEIVED
      //       ),
      //     disable: (item: ApplicationWith<"userEmails">) => {
      //       const found = item.userEmails?.find(
      //         (email) =>
      //           email.template === UserEmailTemplate.APPLICATION_FEEDBACK_REMINDER
      //       );
      //       return !!found;
      //     },
      //   },
      //   {
      //     actionName: "Vote",
      //     url: "/applications/:id/voting",
      //     icon: <HowToVote />,
      //     hide: (item: ApplicationWith<"userEmails">) =>
      //       !canTransitionStatus(item.status, ApplicationStatus.VOTING),
      //   },
      //   {
      //     actionName: "Waitlist",
      //     url: "/applications/:id/waitlist",
      //     icon: <HourglassEmpty />,
      //     hide: (item: ApplicationWith<"userEmails">) =>
      //       !canTransitionStatus(item.status, ApplicationStatus.WAITLISTED),
      //   },
      //   {
      //     actionName: "Cancel",
      //     url: "/applications/:id/cancel",
      //     icon: <CancelOutlined />,
      //     validationSchema: yup.object({ reason: yup.string().required() }),
      //     hide: (item: ApplicationWith<"userEmails">) =>
      //       !canTransitionStatus(item.status, ApplicationStatus.CANCELED),
      //   },
      // ],
      multipleActions: [],
      onRowClick: (e) => {
        console.log(e);
      },
    };
    return { ...sharedProps, validationSchema };
  };
