import { Add, Close } from "@mui/icons-material";
import { Box, Checkbox } from "@mui/material";
import * as yup from "yup";
import {
  GameModel,
  GamesOnMeetupsModel,
  GamesOnMeetupsWith,
  MeetupModel,
  UserModel,
  UserRole,
} from "@pastukh-dm/wboard-games";
import { useNavigate } from "react-router-dom";
import { DialogAction } from "src/components/dialogs/DialogAction";
import {
  deprecatedCreateEntityFieldMeta,
  DeprecatedEntityFieldType,
} from "src/components/EntityTable/DeprecatedEntityForm";
import { ListTable } from "src/components/ListTable";
import { useFetch } from "src/hooks/useFetch";
import { AddMeetupGameButton } from "../components/AddMeetupGameButton";

type Props = { meetup: MeetupModel };

export const MeetupGamesTab = ({ meetup }: Props) => {
  const navigate = useNavigate();

  const { data: meetupGames, refetch } = useFetch<
    GamesOnMeetupsWith<"game" | "user">[]
  >({
    url: `/meetups/${meetup.id}/games`,
  });

  return (
    <>
      {meetupGames && (
        <>
          <Box mb={4}>
            <AddMeetupGameButton />
            {/* <DialogAction
              url={`/meetups/${meetup.id}/games`}
              icon={<Add />}
              text={"Add game"}
              onSuccess={refetch}
              validationSchema={yup.object({
                userId: yup
                  .string()
                  .required()
                  .meta(
                    createEntityFieldMeta(EntityFieldType.AUTOCOMPLETE, {
                      options: (allUsers || []).map((user) => ({
                        value: user.id,
                        label: `${user.name} (${user.email})`,
                      })),
                    })
                  ),
                geekId: yup
                  .string()
                  .required()
                  .meta(
                    createEntityFieldMeta(EntityFieldType.AUTOCOMPLETE, {
                      options: (allGames || []).map((game) => ({
                        value: game.geekId.toString(),
                        label: `${game.name} (${game.geekId})`,
                      })),
                    })
                  ),
                isMainGame: yup
                  .boolean()
                  .meta(createEntityFieldMeta(EntityFieldType.CHECKBOX)),
              })}
              defaultValues={{
                isMainGame: true,
                userId: allUsers?.find((user) => user.role === UserRole.ADMIN)
                  ?.id,
              }}
            /> */}
          </Box>
          <ListTable
            columns={[
              { label: "GeekID", render: (item) => item.game.geekId },
              { label: "Name", render: (item) => item.game.name },
              { label: "Weight", render: (item) => item.game.weight },
              { label: "Min. players", render: (item) => item.game.minPlayers },
              { label: "Max. players", render: (item) => item.game.maxPlayers },
              {
                label: "Min. playtime",
                render: (item) => item.game.minPlaytime,
              },
              {
                label: "Max. playtime",
                render: (item) => item.game.maxPlaytime,
              },
              {
                label: "Owner",
                render: (item) => `${item.user?.name} (${item.user?.email})`,
              },
              {
                label: "Main Game",
                render: (item) => (
                  <Checkbox checked={item.isMainGame} disabled />
                ),
              },
              {
                action: (item) => (
                  <DialogAction
                    url={`/meetups/${item.meetupId}/games/${item.gameId}`}
                    method="delete"
                    icon={<Close color="error" />}
                    text="Delete game from meetup"
                    color="error"
                    onSuccess={refetch}
                  />
                ),
              },
            ]}
            data={meetupGames}
            // onRowClick={(item) => navigate(`/applications/${item.id}`)}
          />
        </>
      )}
    </>
  );
};
