import {
  CancelOutlined,
  Check,
  CheckCircleOutline,
  Close,
  Email,
  Grain,
  HourglassEmpty,
  HowToVote,
  MailLock,
  MoreVert,
  RateReview,
  RestartAlt,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactNode, useMemo } from "react";
import {
  ListTable,
  ListTableColumn,
  ListTableColumns,
} from "../../../components/ListTable";
import { ListTableAction } from "../../../components/ListTableAction";
import { formatDatetime } from "../../../helpers/formatHelper";
import { useFetch } from "../../../hooks/useFetch";
import { canTransitionStatus } from "../../../utils/StatusFlow";
import * as yup from "yup";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  ApplicationModel,
  ApplicationStatus,
  ApplicationWith,
  GameModel,
  MeetupModel,
  UserEmailTemplate,
  UserModel,
} from "@pastukh-dm/wboard-games";
import {
  EntityItem,
  ListTableMultipleAction,
} from "src/components/ListTableMultipleAction";
import { BackdropLoading } from "src/components/BackdropLoading";
import {
  deprecatedCreateEntityFieldMeta,
  DeprecatedEntityFieldType,
} from "src/components/EntityTable/DeprecatedEntityForm";
import { MeetupApplicationMenuButton } from "../components/MeetupApplicationMenuButton";
import { AddMeetupApplicationButton } from "../components/AddMeetupApplicationButton";
import { grey } from "@mui/material/colors";
import { FeedbackChip } from "../components/FeedbackChip";
import { DepositStatusChip } from "../components/DepositStatusChip";

// set the UTC time zone
dayjs.extend(utc);
dayjs.utc();

// set the Warsaw time zone
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Warsaw");

dayjs.extend(relativeTime);

type Props = { meetup: MeetupModel };

type FetchedApplication = ApplicationWith<
  "user" | "userEmails" | "assignedGame" | "deposit" | "feedback"
> & {
  libGames: GameModel[];
  bringGames: GameModel[];
  allApplications: ApplicationWith<"meetup">[];
};

export const MeetupApplicationsTab = ({ meetup }: Props) => {
  const navigate = useNavigate();

  const {
    data: applications,
    refetch,
    isLoading,
  } = useFetch<FetchedApplication[]>({
    url: `/meetups/${meetup.id}/applications`,
  });

  const { data: meetups } = useFetch<MeetupModel[]>({
    url: "/meetups/",
  });
  const { data: users } = useFetch<UserModel[]>({ url: "/users" });

  const groupApplicationsByStatus = (items: FetchedApplication[]) => {
    const initialState = Object.values(ApplicationStatus).reduce(
      (res, key) => (res[key] = []) && res,
      {} as { [key in ApplicationStatus]: FetchedApplication[] }
    );
    return items.reduce((res, item) => {
      res[item.status].push(item);
      return res;
    }, initialState);
  };
  const groupedApplications = groupApplicationsByStatus(
    applications
      ? applications.sort((a, b) =>
          !!a.user.name && !!b.user.name && a.user.name > b.user.name ? 1 : -1
        )
      : []
  );

  const historyColumn: ListTableColumn<FetchedApplication> = {
    // width: 100,
    render: (item) => {
      const colorByStatus: Record<ApplicationStatus, string> = {
        [ApplicationStatus.CREATED]: "#ccc",
        [ApplicationStatus.APPLIED]: "#999",
        [ApplicationStatus.VERIFIED]: "#999",
        [ApplicationStatus.REJECTED]: "#f00",
        [ApplicationStatus.WAITLISTED]: "#ffec0f",
        [ApplicationStatus.ACCEPTED]: "#999",
        [ApplicationStatus.CONFIRMED]: "#999",
        [ApplicationStatus.DEPOSITED]: "#999",
        [ApplicationStatus.VOTING]: "#999",
        [ApplicationStatus.VOTED]: "#999",
        [ApplicationStatus.GAME_ASSIGNED]: "#999",
        [ApplicationStatus.ARRIVED]: "#999",
        [ApplicationStatus.NO_ARRIVAL]: "#f00",
        [ApplicationStatus.FEEDBACK_REQUESTED]: "#b1e8f7",
        [ApplicationStatus.FEEDBACK_RECEIVED]: "#42c8ec",
        [ApplicationStatus.DEPOSIT_DONATED]: "#fff",
        [ApplicationStatus.DEPOSIT_RETURNED]: "#fff",
        [ApplicationStatus.CANCELED]: "#ffaeae",
      };
      return (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            minWidth: "100px",
            alignItems: "center",
          }}
        >
          {item.allApplications
            .sort((a, b) => (a.meetup.startAt > b.meetup.startAt ? -1 : 1))
            .map((application) => (
              <Tooltip
                key={application.id}
                placement="top"
                title={
                  <>
                    {application.meetup.name}
                    <br />
                    {application.status}
                    <br />
                    {formatDatetime(application.meetup.startAt)}
                  </>
                }
              >
                <Box
                  sx={{
                    width: 10,
                    height: application.id === item.id ? 5 : 10,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#fff",
                    background: colorByStatus[application.status],
                  }}
                />
              </Tooltip>
            ))}
        </Box>
      );
    },
  };

  const firstColumns: ListTableColumns<FetchedApplication> = [
    {
      width: 250,
      label: "User",
      render: ({ id, user, allApplications }) => {
        return (
          <>
            <b>
              {user.firstName} {user.lastName}
            </b>{" "}
            {user?.name && `(${user?.name})`}
            <br />
            <small>{user?.email}</small>
          </>
        );
      },
    },
  ];

  const depositColumn: ListTableColumn<FetchedApplication> = {
    label: "Deposit",
    render: (item) => <DepositStatusChip item={item} onChange={refetch} />,
  };

  const middleColumns: ListTableColumns<FetchedApplication> = [
    {
      label: "Games",
      width: 200,
      render: (val) => {
        const render = (game: GameModel) => (
          <Box>
            <a
              href={`https://boardgamegeek.com/boardgame/${game.geekId}`}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {game.name}
            </a>
          </Box>
        );
        return (
          <Stack>
            {val.libGames && val.libGames.length > 0 && (
              <Box>
                <small>
                  <b>Added</b>: {val.libGames.map(render)}
                </small>
              </Box>
            )}
            {val.bringGames && val.bringGames.length > 0 && (
              <Box>
                <small>
                  <b>Bring</b>: {val.bringGames.map(render)}
                </small>
              </Box>
            )}
          </Stack>
        );
      },
    },
    historyColumn,
  ];

  const lastColumns: ListTableColumns<FetchedApplication> = [
    {
      label: "Status",
      width: 150,
      render: (val) => (
        <>
          <b>{val.status}</b>
          <br />
          <small>{formatDatetime(val?.updatedAt)}</small>
        </>
      ),
    },

    // historyColumn,
  ];

  const actionColumns: ListTableColumns<FetchedApplication> = [
    {
      action: (val) => <NotifyAssignedAction item={val} onSuccess={refetch} />,
    },
    {
      action: (val) => <NotifyAcceptedAction item={val} onSuccess={refetch} />,
    },
    {
      action: (val) => (
        <NotifyWaitlistedAction item={val} onSuccess={refetch} />
      ),
    },
    {
      action: (val) => <NotifyConfirmedAction item={val} onSuccess={refetch} />,
    },
    {
      action: (val) => <NotifyAppliedAction item={val} onSuccess={refetch} />,
    },
    {
      action: (val) => <AcceptSoftAction item={val} onSuccess={refetch} />,
    },
    {
      action: (val) => <WaitlistAction item={val} onSuccess={refetch} />,
    },
    {
      action: (val) => <StartVotingAction item={val} onSuccess={refetch} />,
    },
    {
      action: (val) => <ArrivedAction item={val} onSuccess={refetch} />,
    },
    {
      action: (val) => <NotArrivedAction item={val} onSuccess={refetch} />,
    },
    {
      action: (val) => <FeedbackAction item={val} onSuccess={refetch} />,
    },
    // {
    //   action: (val) => <CancelAction item={val} onSuccess={refetch} />,
    // },
    // {
    //   action: (val) => <ApplySoftAction item={val} onSuccess={refetch} />,
    // },
    {
      width: 10,
      render: (item) => (
        <MeetupApplicationMenuButton
          application={item}
          meetup={meetup}
          refetch={refetch}
          allMeetups={meetups || []}
          allUsers={users || []}
        />
      ),
    },
  ];
  const timeLeftColumn: ListTableColumn<FetchedApplication> = {
    label: "Accepted",
    render: (item) => {
      const acceptedEmail = item.userEmails?.find(
        (email) => email.template === UserEmailTemplate.APPLICATION_ACCEPTED
      );
      if (!acceptedEmail) {
        return "No accepted email";
      }
      return (
        <Box
          sx={{
            whiteSpace: "nowrap",
            color:
              dayjs().diff(acceptedEmail.createdAt, "h") > 24
                ? "#f00"
                : undefined,
            weight:
              dayjs().diff(acceptedEmail.createdAt, "h") > 24 ? 700 : undefined,
          }}
        >
          {dayjs().to(acceptedEmail.createdAt)}
        </Box>
      );
    },
  };
  const feedbackColumn: ListTableColumn<FetchedApplication> = {
    label: "Feedback",
    width: 400,
    render: (item) => {
      return (
        <>
          {item.feedback ? <FeedbackChip feedback={item.feedback} /> : "–"}
          <Box sx={{ borderLeft: `3px solid ${grey[200]}`, pl: 1, mt: 1 }}>
            <i>{item.feedback?.comments}</i>
          </Box>
        </>
      );
    },
  };
  const defaultColumns = [
    ...firstColumns,
    ...middleColumns,
    ...lastColumns,
    ...actionColumns,
  ];
  const timeleftColumns = [
    ...firstColumns,
    ...middleColumns,
    ...lastColumns,
    timeLeftColumn,
    ...actionColumns,
  ];
  const feedbackColumns = [
    ...firstColumns,
    ...middleColumns,
    feedbackColumn,
    depositColumn,
    ...lastColumns,
    ...actionColumns,
  ];

  const sectionsRecord: Record<
    ApplicationStatus,
    {
      title: string;
      items: FetchedApplication[];
      columns: ListTableColumns<FetchedApplication>;
    }
  > = {
    [ApplicationStatus.APPLIED]: {
      title: "APPLIED",
      items: groupedApplications[ApplicationStatus.APPLIED],
      columns: timeleftColumns,
    },
    [ApplicationStatus.VERIFIED]: {
      title: "VERIFIED",
      items: groupedApplications[ApplicationStatus.VERIFIED],
      columns: timeleftColumns,
    },
    [ApplicationStatus.CREATED]: {
      title: "CREATED",
      items: groupedApplications[ApplicationStatus.CREATED],
      columns: timeleftColumns,
    },
    [ApplicationStatus.ACCEPTED]: {
      title: "ACCEPTED",
      items: groupedApplications[ApplicationStatus.ACCEPTED],
      columns: defaultColumns,
    },
    [ApplicationStatus.CONFIRMED]: {
      title: "CONFIRMED",
      items: groupedApplications[ApplicationStatus.CONFIRMED],
      columns: timeleftColumns,
    },
    [ApplicationStatus.DEPOSITED]: {
      title: "DEPOSITED",
      items: groupedApplications[ApplicationStatus.DEPOSITED],
      columns: defaultColumns,
    },
    [ApplicationStatus.VOTING]: {
      title: "VOTING",
      items: groupedApplications[ApplicationStatus.VOTING],
      columns: defaultColumns,
    },
    [ApplicationStatus.VOTED]: {
      title: "VOTED",
      items: groupedApplications[ApplicationStatus.VOTED],
      columns: defaultColumns,
    },
    [ApplicationStatus.GAME_ASSIGNED]: {
      title: "GAME_ASSIGNED",
      items: groupedApplications[ApplicationStatus.GAME_ASSIGNED],
      columns: defaultColumns,
    },
    [ApplicationStatus.ARRIVED]: {
      title: "ARRIVED",
      items: groupedApplications[ApplicationStatus.ARRIVED],
      columns: defaultColumns,
    },
    [ApplicationStatus.FEEDBACK_RECEIVED]: {
      title: "FEEDBACK_RECEIVED",
      items: groupedApplications[ApplicationStatus.FEEDBACK_RECEIVED],
      columns: feedbackColumns,
    },
    [ApplicationStatus.FEEDBACK_REQUESTED]: {
      title: "FEEDBACK_REQUESTED",
      items: groupedApplications[ApplicationStatus.FEEDBACK_REQUESTED],
      columns: defaultColumns,
    },
    [ApplicationStatus.DEPOSIT_DONATED]: {
      title: "DEPOSIT_DONATED",
      items: groupedApplications[ApplicationStatus.DEPOSIT_DONATED],
      columns: defaultColumns,
    },
    [ApplicationStatus.DEPOSIT_RETURNED]: {
      title: "DEPOSIT_RETURNED",
      items: groupedApplications[ApplicationStatus.DEPOSIT_RETURNED],
      columns: defaultColumns,
    },

    [ApplicationStatus.WAITLISTED]: {
      title: "WAITLISTED",
      items: groupedApplications[ApplicationStatus.WAITLISTED],
      columns: defaultColumns,
    },

    [ApplicationStatus.NO_ARRIVAL]: {
      title: "NO_ARRIVAL",
      items: groupedApplications[ApplicationStatus.NO_ARRIVAL],
      columns: defaultColumns,
    },

    [ApplicationStatus.CANCELED]: {
      title: "CANCELED",
      items: groupedApplications[ApplicationStatus.CANCELED],
      columns: defaultColumns,
    },
    [ApplicationStatus.REJECTED]: {
      title: "REJECTED",
      items: groupedApplications[ApplicationStatus.REJECTED],
      columns: defaultColumns,
    },
  };

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <AddMeetupApplicationButton
          meetup={meetup}
          refetch={refetch}
          allUsers={users || []}
        />
      </Box>
      <BackdropLoading show={isLoading} />
      {Object.values(sectionsRecord).map(
        (section) =>
          section.items.length > 0 && (
            <Box sx={{ mb: 6 }} key={section.title}>
              <Typography variant="h5" mb={2}>
                <Badge badgeContent={section.items.length} sx={{ pr: "13px" }}>
                  {section.title}
                </Badge>
              </Typography>
              <Box sx={{ height: "auto", width: "100%" }}>
                <ListTableMultipleAction
                  {...{
                    actionName: "Send email",
                    items: section.items,
                    validationSchema: yup.object({
                      subject: yup.string().required(),
                      title: yup.string().optional(),
                      body: yup.string().required(),
                      includeApplication: yup
                        .boolean()
                        .required()
                        .meta(
                          deprecatedCreateEntityFieldMeta(
                            DeprecatedEntityFieldType.CHECKBOX
                          )
                        ),
                    }),
                    defaultValues: () => ({
                      subject: `Last chance to confirm - ${meetup.name}`,
                      title: "Last chance to confirm",
                      body: "Confirm your application for the meetup quickly. Otherwise it will be canceled.",
                      includeApplication: true,
                    }),
                    url: "/applications/sendGenericEmail",
                    onSuccess: () => {
                      // debugger;
                    },
                    icon: <Email />,
                  }}
                />

                <ListTable
                  columns={section.columns}
                  data={section.items}
                  onRowClick={(item) => navigate(`/applications/${item.id}`)}
                />
              </Box>
            </Box>
          )
      )}
    </>
  );
};
const BaseAction = <T extends EntityItem>({
  actionName,
  item,
  skipDialog,
  url,
  onSuccess,
  icon,
  hide,
  show,
  validationSchema,
}: {
  actionName: string;
  item: T;
  skipDialog?: boolean;
  url: string;
  onSuccess: () => void;
  icon: ReactNode;
  hide?: (item: T) => boolean;
  show?: (item: T) => boolean;
  validationSchema?: yup.AnyObjectSchema | any;
}) => {
  return (
    <ListTableAction
      {...{
        actionName,
        item,
        skipDialog,
        url,
        onSuccess,
        icon,
        hide,
        show,
        validationSchema,
      }}
    />
  );
};

const ApplySoftAction = ({
  item,
  onSuccess,
}: {
  item: ApplicationModel;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      actionName: "Reset",
      item,
      skipDialog: true,
      url: "/applications/:id/applySoft",
      onSuccess,
      icon: <RestartAlt />,
      hide: (item: ApplicationModel) =>
        !canTransitionStatus(item.status, ApplicationStatus.APPLIED),
    }}
  />
);

const AcceptSoftAction = ({
  item,
  onSuccess,
}: {
  item: FetchedApplication;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      actionName: "Soft Accept",
      item,
      skipDialog: true,
      url: "/applications/:id/acceptSoft",
      onSuccess,
      icon: <CheckCircleOutline />,
      hide: (item: FetchedApplication) =>
        !canTransitionStatus(item.status, ApplicationStatus.ACCEPTED),
    }}
  />
);

const NotifyAcceptedAction = ({
  item,
  onSuccess,
}: {
  item: FetchedApplication;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      actionName: "Notify Accepted",
      item,
      skipDialog: true,
      url: "/applications/:id/acceptNotify",
      onSuccess,
      icon: <Email />,
      show: (item: FetchedApplication) => {
        const res =
          item.status === ApplicationStatus.ACCEPTED &&
          !item.userEmails?.some((email) => {
            return (
              email.template === UserEmailTemplate.APPLICATION_ACCEPTED &&
              email.messageId
            );
          });
        // console.log(item.status, res);
        return res;
      },
    }}
  />
);

const NotifyAssignedAction = ({
  item,
  onSuccess,
}: {
  item: FetchedApplication;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      actionName: "Notify Assigned",
      item,
      skipDialog: true,
      url: "/applications/:id/assign",
      onSuccess,
      icon: <MailLock />,
      show: (item: FetchedApplication) => {
        const res =
          !!item.assignedGameId &&
          !item.userEmails?.some((email) => {
            return (
              email.template === UserEmailTemplate.APPLICATION_ASSIGNED &&
              email.messageId &&
              email?.templateParams &&
              item?.assignedGame?.name &&
              email.templateParams.includes(item.assignedGame.name)
            );
          });
        return res;
      },
    }}
  />
);
const NotifyWaitlistedAction = ({
  item,
  onSuccess,
}: {
  item: FetchedApplication;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      actionName: "Notify Waitlisted",
      item,
      skipDialog: true,
      url: "/applications/:id/waitlistNotify",
      onSuccess,
      icon: <Email />,
      show: (item: FetchedApplication) => {
        const res =
          item.status === ApplicationStatus.WAITLISTED &&
          !item.userEmails?.some((email) => {
            return (
              email.template === UserEmailTemplate.APPLICATION_WAITLISTED &&
              email.messageId
            );
          });
        // console.log(item.status, res);
        return res;
      },
    }}
  />
);
const NotifyConfirmedAction = ({
  item,
  onSuccess,
}: {
  item: FetchedApplication;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      actionName: "Notify Confirmed",
      item,
      skipDialog: true,
      url: "/applications/:id/confirmNotify",
      onSuccess,
      icon: <Email />,
      show: (item: FetchedApplication) => {
        const res =
          item.status === ApplicationStatus.CONFIRMED &&
          !item.userEmails?.some((email) => {
            return (
              email.template === UserEmailTemplate.APPLICATION_CONFIRMED &&
              email.messageId
            );
          }) &&
          !!item.userEmails?.some((email) => {
            return (
              email.template === UserEmailTemplate.APPLICATION_ACCEPTED &&
              dayjs(email.createdAt).isBefore(dayjs().subtract(4, "h"))
            );
          });
        // console.log(item.status, res);
        return res;
      },
    }}
  />
);
const NotifyAppliedAction = ({
  item,
  onSuccess,
}: {
  item: FetchedApplication;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      actionName: "Remind accepted",
      url: "/applications/:id/remind",

      item,
      skipDialog: true,
      onSuccess,
      icon: <Email />,
      show: (item: FetchedApplication) => {
        const res =
          item.status === ApplicationStatus.ACCEPTED &&
          !item.userEmails?.some((email) => {
            return (
              email.template ===
                UserEmailTemplate.APPLICATION_ACCEPTED_REMINDER &&
              email.messageId
            );
          }) &&
          !!item.userEmails?.some((email) => {
            return (
              email.template === UserEmailTemplate.APPLICATION_ACCEPTED &&
              dayjs(email.createdAt).isBefore(dayjs().subtract(4, "h"))
            );
          });
        return res;
      },
    }}
  />
);
const CancelAction = ({
  item,
  onSuccess,
}: {
  item: FetchedApplication;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      actionName: "Cancel",
      item,
      onSuccess,
      url: "/applications/:id/cancel",
      icon: <CancelOutlined />,
      validationSchema: yup.object({ reason: yup.string().required() }),
      show: (item: FetchedApplication) =>
        !!canTransitionStatus(item.status, ApplicationStatus.CANCELED),
    }}
  />
);
const ArrivedAction = ({
  item,
  onSuccess,
}: {
  item: FetchedApplication;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      actionName: "Arrived",
      item,
      onSuccess,
      url: "/applications/:id/arrived",
      icon: <Check color="success" />,
      skipDialog: true,
      show: (item: FetchedApplication) =>
        !!canTransitionStatus(item.status, ApplicationStatus.ARRIVED),
    }}
  />
);
const NotArrivedAction = ({
  item,
  onSuccess,
}: {
  item: FetchedApplication;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      actionName: "Not Arrived",
      item,
      onSuccess,
      url: "/applications/:id/noArrival",
      icon: <Close color="error" />,
      skipDialog: true,
      show: (item: FetchedApplication) =>
        !!canTransitionStatus(item.status, ApplicationStatus.NO_ARRIVAL),
    }}
  />
);
const FeedbackAction = ({
  item,
  onSuccess,
}: {
  item: FetchedApplication;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      actionName: "Request feedback",
      item,
      onSuccess,
      url: "/applications/:id/askFeedback",
      icon: <RateReview />,
      skipDialog: true,
      show: (item: FetchedApplication) =>
        !!canTransitionStatus(
          item.status,
          ApplicationStatus.FEEDBACK_REQUESTED
        ),
    }}
  />
);
const WaitlistAction = ({
  item,
  onSuccess,
}: {
  item: ApplicationModel;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      item,
      onSuccess,
      actionName: "Soft Waitlist",
      url: "/applications/:id/waitlistSoft",
      icon: <HourglassEmpty />,
      skipDialog: true,
      hide: (item: ApplicationModel) =>
        !canTransitionStatus(item.status, ApplicationStatus.WAITLISTED),
    }}
  />
);
const StartVotingAction = ({
  item,
  onSuccess,
}: {
  item: ApplicationModel;
  onSuccess: () => void;
}) => (
  <BaseAction
    {...{
      item,
      onSuccess,
      actionName: "Start voting",
      url: "/applications/:id/voting",
      icon: <HowToVote />,
      skipDialog: true,
      hide: (item) =>
        !canTransitionStatus(item.status, ApplicationStatus.VOTING),
    }}
  />
);
