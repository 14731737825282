import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CreateEntityDialog } from "src/components/dialogs/EntityDialog/CreateEntityDialog";
import * as yup from "yup";
import {
  deprecatedCreateEntityFieldMeta,
  DeprecatedEntityFieldType,
} from "src/components/EntityTable/DeprecatedEntityForm";
import {
  GameModel,
  GamesOnMeetupsWith,
  MeetupLevel,
  MeetupModel,
  UserModel,
  UserRole,
} from "@pastukh-dm/wboard-games";
import { useFetch } from "src/hooks/useFetch";
import {
  EntityDialogFields,
  EntityDialogFieldSelectOption,
  FieldType,
} from "src/components/dialogs/EntityDialog/EntityDialogForm";
import * as z from "zod";

export const AddMeetupGameButton = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const { id } = useParams();

  const { data: allGames } = useFetch<GameModel[]>({
    url: `/games`,
  });
  const { data: meetupGames } = useFetch<GamesOnMeetupsWith<"game" | "user">[]>(
    {
      url: `/meetups/${id}/games`,
    }
  );
  const { data: libraryGames } = useFetch<GameModel[]>({
    url: `/games/library`,
  });
  const { data: allUsers } = useFetch<UserModel[]>({
    url: `/users`,
  });
  const { data: meetup } = useFetch<MeetupModel>({
    url: `/meetups/${id}`,
  });

  const gameOptions = useMemo(() => {
    return (allGames || [])
      .filter(
        (game) =>
          !meetupGames?.find(
            (meetupGame) => meetupGame.game.geekId === game.geekId
          )
      )
      .sort((a, b) =>
        !!a.weight && !!b.weight && a.weight < b.weight ? 1 : -1
      )
      .map((game) => ({
        value: game.geekId.toString(),
        label: `${
          libraryGames?.some((libGame) => libGame.geekId === game.geekId)
            ? "🔹 "
            : ""
        }${game.weight?.toFixed(2)} - ${game.name} - ${game.geekId}`,
        group: meetup?.level ? getGameGroup(game, meetup.level) : undefined,
      }))
      .sort(sortOptions);
  }, [allGames, meetupGames, meetup?.level]);

  const fields = useMemo<EntityDialogFields>(
    () => ({
      userId: {
        type: FieldType.AUTOCOMPLETE,
        options: (allUsers || []).map((user) => ({
          value: user.id,
          label: `${user.name} (${user.email})`,
        })),
        validation: z.string(),
        defaultValue: allUsers?.find((user) => user.role === UserRole.ADMIN)
          ?.id,
      },
      isMainGame: {
        type: FieldType.CHECKBOX,
        defaultValue: true,
      },
      geekIds: {
        type: FieldType.AUTOCOMPLETE_MULTIPLE,
        options: gameOptions,
        validation: z.string().array(),
        groupOptions: true,
        // renderOption: (option) => <div>12{option.value}</div>,
      },
    }),
    [allUsers, gameOptions]
  );

  return (
    <>
      <CreateEntityDialog
        createUrl={`/meetups/${id}/games`}
        entityName={"Meetup game"}
        onSuccess={() => {
          navigate(0);
        }}
        fields={fields}
        open={open}
        onOpenChange={setOpen}
      />
      <Button
        startIcon={<Add />}
        onClick={() => setOpen(true)}
        variant="outlined"
        color="primary"
      >
        Meetup game
      </Button>
    </>
  );
};

type GameGroup = "Main" | "Cooldown" | "Other";

type GameRange = { from?: number; to?: number };
type GameParams = { weight: GameRange; playtime: GameRange };
const gameParamsByMeetupLevel: Record<
  MeetupLevel,
  { main: GameParams; cooldown: GameParams }
> = {
  [MeetupLevel.LIGHT]: {
    main: { weight: { to: 2.6 }, playtime: { from: 60 } },
    cooldown: { weight: { to: 2.6 }, playtime: { to: 60 } },
  },
  [MeetupLevel.MEDIUM]: {
    main: { weight: { from: 2.6, to: 3.4 }, playtime: { from: 60 } },
    cooldown: { weight: { to: 3.4 }, playtime: { to: 60 } },
  },
  [MeetupLevel.HEAVY]: {
    main: { weight: { from: 3.4, to: 5 }, playtime: { from: 60 } },
    cooldown: { weight: { to: 5 }, playtime: { to: 60 } },
  },
};

const inGameRange = (num: number | null, range: GameRange) => {
  if (!num) {
    return false;
  }
  const min = range.from || -Infinity;
  const max = range.to || Infinity;
  if (min <= num && num <= max) {
    return true;
  }
  return false;
};
const gameInGameRange = (game: GameModel, gameParams: GameParams) =>
  inGameRange(game.weight, gameParams.weight) &&
  inGameRange(game.maxPlaytime, gameParams.playtime);

const getGameGroup = (game: GameModel, level: MeetupLevel): GameGroup => {
  const gameParams = gameParamsByMeetupLevel[level];
  if (gameInGameRange(game, gameParams.main)) {
    return "Main";
  }
  if (gameInGameRange(game, gameParams.cooldown)) {
    return "Cooldown";
  }
  return "Other";
};

const sortOptions = (
  a: EntityDialogFieldSelectOption<string, GameGroup>,
  b: EntityDialogFieldSelectOption<string, GameGroup>
) => {
  if (!!a.group && !!b.group) {
    if (a.group !== b.group) {
      const groupOrder: GameGroup[] = ["Main", "Cooldown", "Other"];
      return groupOrder.indexOf(a.group) > groupOrder.indexOf(b.group) ? 1 : -1;
    }
    // if (!!a.label && !!b.label) {
    //   if (a.group === b.group) {
    //     return a.label > b.label ? 1 : -1;
    //   }
    // }
  }
  return 0;
};
